import './App.css';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

function App() {
	const [allTimes, setAllTimes] = useState([]);

	const [formattedTimeDone, setFormattedTimeDone] = useState('HH:MM');
	const [formattedTimeLeft, setFormattedTimeLeft] = useState('HH:MM');

	const [specialHour, setSpecialHour] = useState(0);
	const [specialMinutes, setSspecialMinutes] = useState(0);

	const [overwrite, setOverwrite] = useState(false);

	function minutesToHourMinuteString(minutes) {
		const hours = Math.floor(minutes / 60);
		const remainingMinutes = minutes % 60;
		return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
	}

	function add_special_time() {
		setOverwrite(true);
		let minutes = parseInt(specialHour * 60) + parseInt(specialMinutes);
		setAllTimes((prevTimes) => [...prevTimes, minutes]);
	}

	function new_badge() {
		setOverwrite(true);
		const midnight = new Date();
		midnight.setHours(0, 0, 0, 0);

		const new_minute = Math.floor((Date.now() - midnight.getTime()) / (60 * 1000));
		setAllTimes((prevTimes) => [...prevTimes, new_minute]);
	}

	const supprimer_dernier = () => {
		setOverwrite(true);
		if (allTimes.length === 0) {
		} else {
			setAllTimes((prevIntegers) => prevIntegers.slice(0, -1));
		}
	};

	function new_week() {
		setOverwrite(true);
		setAllTimes([]);
	}

	function update() {
		let totalDone = 0;
		for (let i = 0; i < allTimes.length; i++) {
			if (i % 2 === 0) {
				if (i + 1 < allTimes.length) {
					totalDone += allTimes[i + 1] - allTimes[i];
				}
			}
		}
		let hoursLeft = 2160 - totalDone;
		setFormattedTimeDone(minutesToHourMinuteString(totalDone));
		setFormattedTimeLeft(minutesToHourMinuteString(hoursLeft));
	}

	useEffect(() => {
		update();
		if (overwrite) {
			Cookies.set('allTimes', JSON.stringify(allTimes), { expires: 30 });
		}
	}, [allTimes]);

	useEffect(() => {
		const storedArray = Cookies.get('allTimes');
		if (storedArray) {
			setAllTimes(JSON.parse(storedArray));
		}
	}, []);

	return (
		<div className="App">
			<div className="times">
				<h1 className="time-done">{formattedTimeDone}</h1>
				<h3 className="time-todo">{formattedTimeLeft}</h3>
			</div>
			<div className="badge">
				<div className="list_times">
					{allTimes.map((integer, index) => (
						<p className="hour" key={index}>
							{minutesToHourMinuteString(integer)}
						</p>
					))}
				</div>
				<button className="boutton-badge" onClick={new_badge}>
					Badge
				</button>
				<button className="boutton-supprimer" onClick={supprimer_dernier}>
					Supprimer dernier
				</button>
			</div>
			<div className="add-special">
				<input type="number" min={0} max={24} value={specialHour} onChange={(e) => setSpecialHour(e.target.value)}></input>
				<input type="number" min={0} max={60} value={specialMinutes} onChange={(e) => setSspecialMinutes(e.target.value)}></input>
				<button className="special-ajout" onClick={add_special_time}>
					Ajouter
				</button>
			</div>
			<div className="news">
				<button className="new-week" onClick={new_week}>
					Nouvelle semaine
				</button>
			</div>
		</div>
	);
}

export default App;
